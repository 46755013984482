<template>
  <div>
    <div class="flex justify-between mb-3 items-center flex-wrap">
      <a-input placeholder="Buscar..." v-model="query.search" @input="handleTypingSearch" />
      <div>
        <a-button v-if="user.isAdmin" :to="router.createPath" as="router-link">
          Crear {{ isCoordinator ? 'coordinador' : 'profesor' }}
        </a-button>
<!--        <a-dropdown v-if="user.isAdmin" class="ml-2">-->
<!--          <template #menu>-->
<!--            <a-dropdown-item>-->
<!--              <a target="_blank"-->
<!--                 :href="`${url}/api/teachers/reports/list?period_id=${period.id}`">-->
<!--                Descargar reporte-->
<!--              </a>-->
<!--            </a-dropdown-item>-->
<!--          </template>-->
<!--        </a-dropdown>-->
      </div>
    </div>

    <div class="flex justify-between mb-3 mt-2 items-center">
      <h3 class="text-2xl">
        {{ isCoordinator ? lang.coordinators.coordinators : lang.teachers.teachers }}
      </h3>
      <div v-if="user.isAdmin" class="flex h-10">
        <a-segmented-control v-model="control" :segments="segments" />
      </div>
    </div>

    <div class="space-y-4 pb-4">
      <template v-if="loading">
        <a-skeleton class="w-full h-20 rounded-2xl" v-for="n in 3" :key="n" />
      </template>
      <template v-else-if="!loading && pagination.data.filter(a=> a.enabled === true).length && control === 0">
        <teacher-card
          v-for="teacher in pagination.data.filter(a=> a.enabled === true)"
          @updated="fetch({ page: 1 })"
          :router="router"
          :specializations="specializations"
          :coordinated_specializations="coordinated_specializations"
          :period="period"
          :teacher="teacher"
          :key="teacher.id" />
      </template>
      <template v-else-if="!loading && pagination.data.filter(a=> a.enabled === false).length && control === 1">
        <teacher-card
          v-for="teacher in pagination.data.filter(a=> a.enabled === false)"
          @updated="fetch({ page: 1 })"
          :router="router"
          :period="period"
          :teacher="teacher"
          :key="teacher.id" />
      </template>
      <template v-else>
        <div class="flex flex-col space-y-2 p-16">
          <h1 class="text-2xl text-center">No hay datos encontrados</h1>
          <span class="text-center">
          <button class="text-primary p-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary inline-flex items-center justify-center rounded-full" @click="fetchTeachers(query)">Reintentar</button>
        </span>
        </div>
      </template>
    </div>

    <a-paginate
      :current="pagination.current_page"
      :total="pagination.total"
      @page-changed="fetch({ page: $event })"
      :per-page="pagination.per_page"
      class="mt-3 pb-4"/>
<!--    <a-laravel-pagination align="right" :data="pagination" @change="fetchTeachers({ ...query, page: $event })" class="mt-3"/>-->
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import TeacherCard from './components/teacher-card'
import { URL } from '@/constants'

export default {
  components: {
    TeacherCard
  },
  props: {
    isCoordinator: { required: false, type: Boolean, default: false },
    router: { required: false, type: Object, default: () => ({}) },
  },
  data: () => ({
    url: URL,
    segments: [
      { id: 0, title: "Activos" },
      { id: 1, title: "Inactivos" },
    ],
    control: 0,
    period: {},
    specializations: [],
    coordinated_specializations: [],
    query: {
      limit: 10,
      enabled: true,
      with: `courses,courseSections.semester,courseSections.section,courseSections.specialization,courseSections.course,schedules`.replaceAll(/\s+/gm, '').trim(),
      sort_by: 'last_name,asc',
    },
    timeout: null,
    teacherDataTest: []
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.teachers.pagination,
      loading: state => state.teachers.loading
    }),
    ...mapGetters({
      userCanDo: 'users/userCanDo',
      lang: 'lang'
    }),
  },
  methods: {
    ...mapActions({
      fetchTeachers: 'teachers/index',
      fetchCoordinators: 'teachers/indexCoordinators'
    }),
    toggleActiveTeachers() {
      this.query.enabled = !this.query.enabled
      this.$router.replace({ query: { ...this.query } })
        .catch(() => null)
    },
    handleTypingSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.router.createPath.name === 'teachers.create' ? this.fetchTeachers({ ...this.query, page: 1 }) : this.fetchCoordinators({ ...this.query, page: 1})
      }, 1000);
    },
    fetch(query = {}) {
      let endpoints = [];
      if(this.router.createPath.name === 'teachers.create'){
        endpoints = [
          this.$repository.periods.enabled(),
          this.fetchTeachers({
            ...this.query,
            ...query,
          }),
        ]   
      } else {
        endpoints = [
          this.$repository.periods.enabled(),
          this.fetchCoordinators({
            ...this.query,
            ...query,
          }),
        ]  
      }


      if (this.user.isAdmin) {
        endpoints.splice(1, 0, this.$repository.specializations.index({ limit: 0, enabled: true }))
      }

      this.user.isCoordinator ? endpoints.push(this.$repository.teachers.coordinateSpecializations(this.user.teacher.id)) : null
      Promise.all(endpoints)
        .then(([{ data }, { data: specializations }, { data: coordinated_specializations }]) => {
          this.period = data
          this.user.isCoordinator ? this.coordinated_specializations = coordinated_specializations : null
          if (Array.isArray(specializations)) {
            if(this.router.createPath.name === 'teachers.create'){
              this.specializations = specializations;
            } else {
              const coordinatorSpecializations = this.pagination.data.flatMap(a=> a.specializations)
              this.specializations = specializations
              .filter(specialization => 
              !coordinatorSpecializations.some(coordinatorSpec => coordinatorSpec.id === specialization.id))
            }
          }
        })
    }
  },
  watch: {
    'query.enabled'(enabled) {
      if(this.router.createPath.name === 'teachers.create'){

        this.fetchTeachers({
          ...this.query,
          page: 1,
          enabled
        })
      } else {
        this.fetchCoordinators({
          ...this.query,
          page: 1,
          enabled
        })
      }
    },
    control() {
      this.query.enabled = !this.query.enabled
      this.$router.replace({ query: { ...this.query } })
        .catch(() => null)
    },
    isCoordinator() {
      this.query.supervisor_teacher = this.user.isCoordinator && !this.isCoordinator ? this.user.teacher.id : null
      this.fetch()
    }
  },
  mounted() {
    this.query.supervisor_teacher = this.user.isCoordinator && !this.isCoordinator ? this.user.teacher.id : null
    this.fetch()
  }
}
</script>
